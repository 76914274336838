import { render, staticRenderFns } from "./EditStudent.vue?vue&type=template&id=7bab9e66&scoped=true&lang=true&"
import script from "./EditStudent.vue?vue&type=script&lang=js&"
export * from "./EditStudent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bab9e66",
  null
  
)

export default component.exports